var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("form", [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", { attrs: { for: "V" } }, [
                _vm._v("Value of resource (V)")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.V,
                    expression: "V"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "number", id: "V" },
                domProps: { value: _vm.V },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.V = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", { attrs: { for: "C" } }, [
                _vm._v("Cost of injury (C)")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.C,
                    expression: "C"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "number", id: "C" },
                domProps: { value: _vm.C },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.C = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group col-md-4" }, [
              _c("label", { attrs: { for: "T" } }, [
                _vm._v("Cost of time (T)")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.T,
                    expression: "T"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "number", id: "T" },
                domProps: { value: _vm.T },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.T = $event.target.value
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.dove()
                    }
                  }
                },
                [_vm._v("All dove")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.hawk()
                    }
                  }
                },
                [_vm._v("All hawk")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.add("d")
                    }
                  }
                },
                [_vm._v("Add dove")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.add("h")
                    }
                  }
                },
                [_vm._v("Add hawk")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.reset()
                    }
                  }
                },
                [_vm._v("Reset")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.step()
                    }
                  }
                },
                [_vm._v("Step")]
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("canvas", {
      attrs: { id: "populationChart", width: "400px", height: "200px" }
    }),
    _vm._v(" "),
    _c("canvas", {
      attrs: { id: "fitnessChart", width: "400px", height: "200px" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }