var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "points" } }, [
    _c("svg", [_c("rect", { attrs: { x: "0", y: "0" } })])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }