<template>
    <div>
        <div class="row">
            <div class="col-12">
                <form>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="V">Value of resource (V)</label>
                            <input type="number" class="form-control" id="V" v-model="V"/>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="C">Cost of injury (C)</label>
                            <input type="number" class="form-control" id="C" v-model="C"/>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="T">Cost of time (T)</label>
                            <input type="number" class="form-control" id="T" v-model="T"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-2">
                            <button class="btn btn-primary" @click.prevent="dove()">All dove</button>
                        </div>
                        <div class="form-group col-md-2">
                            <button class="btn btn-primary" @click.prevent="hawk()">All hawk</button>
                        </div>
                        <div class="form-group col-md-2">
                            <button class="btn btn-primary" @click.prevent="add('d')">Add dove</button>
                        </div>
                        <div class="form-group col-md-2">
                            <button class="btn btn-primary" @click.prevent="add('h')">Add hawk</button>
                        </div>
                        <div class="form-group col-md-2">
                            <button class="btn btn-primary" @click.prevent="reset()">Reset</button>
                        </div>
                        <div class="form-group col-md-2">
                            <button class="btn btn-primary" @click.prevent="step()">Step</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <canvas id="populationChart" width="400px" height="200px"></canvas>
        <canvas id="fitnessChart" width="400px" height="200px"></canvas>
    </div>
</template>

<script>
    import { mapActions, mapMutations } from 'vuex';
    import { Chart } from 'chart.js';

    export default {
        data() {
            return {
                populationChart: null,
                fitnessChart: null
            }
        },
        methods: {
            ...mapActions([
                'step',
            ]),
            ...mapMutations([
                'hawk',
                'dove',
                'reset',
                'add'
            ])
        },
        mounted() {
            let ctx = document.getElementById('populationChart').getContext('2d');
            this.populationChart = new Chart(ctx, {
                type: 'line',
                data: this.populationChartData,
                responsive: true,
                options: {
                    title: {
                        display: true,
                        text: 'Number of animals by strategy'
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Generation'
                            }
                        }],
                        yAxes: [{
                            id: 'axisCount',
                            display: true,
                            position: 'left',
                            scaleLabel: {
                                display: true,
                                labelString: 'Count'
                            },
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    animation: {
                        duration: 0
                    }
                }
            });
            let ctx2 = document.getElementById('fitnessChart').getContext('2d');
            this.fitnessChart = new Chart(ctx2, {
                type: 'line',
                data: this.fitnessChartData,
                responsive: true,
                options: {
                    title: {
                        display: true,
                        text: 'Average fitness of animals by strategy'
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Generation'
                            }
                        }],
                        yAxes: [{
                            id: 'axisFitness',
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Avg. fitness'
                            },
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    animation: {
                        duration: 0
                    }
                }
            });
        },
        computed: {
            V: {
                get () {
                    return this.$store.state.V
                },
                set (value) {
                    this.$store.commit('setV', +value)
                }
            },
            C: {
                get () {
                    return this.$store.state.C
                },
                set (value) {
                    this.$store.commit('setC', +value)
                }
            },
            T: {
                get () {
                    return this.$store.state.T
                },
                set (value) {
                    this.$store.commit('setT', +value)
                }
            },
            populationChartData() {
                return {
                    labels: this.$store.state.stat.map((e,i) => i),
                    datasets: [{
                        label: 'Number of hawks',
                        data: this.$store.state.stat.map(s => s['h'].cnt),
                        fill: false,
                        pointRadius: 0,
                        borderColor: '#d62728',
                        yAxisID: 'axisCount'
                    },
                    {
                        label: 'Number of doves',
                        data: this.$store.state.stat.map(s => s['d'].cnt),
                        fill: false,
                        pointRadius: 0,
                        borderColor: '#1f77b4',
                        yAxisID: 'axisCount'
                    }]
                }
            },
            fitnessChartData() {
                return {
                    labels: this.$store.state.stat.map((e,i) => i),
                    datasets: [{
                        label: 'Avg. fitness (hawk)',
                        data: this.$store.state.stat.map(s => s['h'].f / s['h'].cnt),
                        fill: false,
                        pointRadius: 0,
                        borderColor: '#d62728',
                        yAxisID: 'axisFitness'
                    },
                    {
                        label: 'Avg. fitness (dove)',
                        data: this.$store.state.stat.map(s => s['d'].f / s['d'].cnt),
                        fill: false,
                        pointRadius: 0,
                        borderColor: '#1f77b4',
                        yAxisID: 'axisFitness'
                    }]
                }
            }
        },
        watch: {
            fitnessChartData() {
                this.fitnessChart.data = this.fitnessChartData;
                this.fitnessChart.update();
            },
            populationChartData() {
                this.populationChart.data = this.populationChartData;
                this.populationChart.update();
            }
        }
    }
</script>
