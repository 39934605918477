var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-6" }, [_c("settings")], 1),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [_c("simulator")], 1)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }