import Vue from 'vue'
import Vuex from 'vuex'
import { run, init_population, stat } from '../simulator';

Vue.use(Vuex)
const STAT_HISTORY = 100;

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    pointNodes: init_population('d'),
    stat: [],
    lastType: 'd',
    V: 50,
    C: 100,
    T: 10
}

function reset(state) {
    state.pointNodes = init_population(state.lastType);
    state.stat = [];
    state.stat.push(stat(state.pointNodes));
}

function updateStat(state) {
    state.stat.push(stat(state.pointNodes));
    state.stat = state.stat.slice(-STAT_HISTORY);
}

// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
    setPointNodes(state, newNodes) {
        console.log('setPointNodes ');
        state.pointNodes = newNodes;
        updateStat(state);
    },
    setV(state, newV) {
        state.V = newV;
    },
    setC(state, newC) {
        state.C = newC;
    },
    setT(state, newT) {
        state.T = newT;
    },
    hawk(state) {
        state.lastType = 'h';
        reset(state);
    },
    dove(state) {
        state.lastType = 'd';
        reset(state);
    },
    reset(state) {
        reset(state);
    },
    add(state, type) {
        let nodes = state.pointNodes;
        for (let i = 0;i<nodes.length;i++) {
            if (nodes[i].type !== type) {
                nodes[i].type = type;
                break;
            }
        }
        updateStat(state);
    }
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
    step({ commit, state }) {
        let newNodes = run(state.pointNodes);
        commit('setPointNodes', newNodes);
    }
}

// getters are functions
const getters = {

}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
