import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Vue from 'vue';
import store from './store';
import App from './App.vue';

new Vue({
    el: '#app',
    store,
    render: h => h(App)
})
