<template>
    <div class="container-fluid">
        <div class="row mt-2">
            <div class="col-6">
                <settings />
            </div>
            <div class="col-6">
                <simulator />
            </div>
        </div>
    </div>
</template>

<script>
    import Settings from './Settings.vue';
    import Simulator from './Simulator.vue';

    export default {
        components: {
            Settings,
            Simulator
        }
    }
</script>
