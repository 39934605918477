import * as d3 from 'd3';
import store from './vue/store';

const POPULATION = 200;
const ROTATION = POPULATION/20;
const WEAK_LIMIT = -30;
const R = 15;
const pointRadius = R*0.7;

function create(type) {
    return {
        type,
        fitness: 0
    }
}

export function init_population(type) {
    const individuals = [];
    for (let i = 0;i<POPULATION;i++) {
        individuals.push(create(type));
    }
    return individuals;
}

function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

function fight(individuals) {
    shuffle(individuals);
    for (let i = 0;i<individuals.length-1;i+=2) {
        let a = individuals[i];
        let b = individuals[i+1];
        fight_each(a, b);
    }
}

function fight_each(a, b) {
    if (a.type === 'd' && b.type === 'd') {
        a.fitness += store.state.V;
        a.fitness -= store.state.T;
        b.fitness -= store.state.T;
    } else if (a.type === 'd' && b.type === 'h') {
        b.fitness += store.state.V;
    } else if (a.type === 'h' && b.type === 'd') {
        a.fitness += store.state.V;
    } else if (a.type === 'h' && b.type === 'h') {
        a.fitness += store.state.V;
        b.fitness -= store.state.C;
    } else {
        throw new Error('impossible happened');
    }
}

function select(individuals) {
    individuals.sort((a,b) => a.fitness-b.fitness);
    let newGenTypes = individuals.map(i => i.type);
    let newGen = individuals.filter(e => e.fitness > WEAK_LIMIT);
    let decrease = POPULATION-newGen.length;
    if (decrease < ROTATION) {
        newGen.splice(0, ROTATION-decrease);
    }
    let newIndividualCnt = POPULATION - newGen.length;
    for (let cnt = 0;cnt < newIndividualCnt;cnt++) {
        let newType = create(newGenTypes[newGenTypes.length-1-cnt]);
        newGen.push(newType);
    }
    return newGen;
}

function selectProportional(individuals) {
    individuals.sort((a,b) => a.fitness-b.fitness);
    const minFitness = Math.abs(individuals[0].fitness);
    const stat = {
        h: 0,
        d: 0
    };
    individuals.forEach(e => {
        stat[e.type] += minFitness + e.fitness;
    });
    const sum = stat['d'] + stat['h'];
    if (sum === 0) {
        return individuals;
    }
    const dcnt = stat['d'] / sum * POPULATION;
    const hcnt = POPULATION-dcnt;
    const newGeneration = [];
    for (let i = 0;i<dcnt;i++) {
        newGeneration.push(create('d'));
    }
    for (let i = 0;i<hcnt;i++) {
        newGeneration.push(create('h'));
    }
    return newGeneration;
}

function selectRandom(individuals) {
    individuals.sort((a,b) => a.fitness-b.fitness);
    const minFitness = individuals[0].fitness;
    const sumFitness = individuals.reduce((acc, cur) => acc + cur.fitness + 200, 0);
    let last = 0;
    const normalized = individuals.map(e => {
        last = last + e.fitness + 200;
        return {
            type: e.type,
            fitness: last
        };
    });
    const random = d3.randomUniform(0, normalized[normalized.length-1].fitness + 1);
    const newGeneration = [];
    for (let i = 0;i<POPULATION;i++) {
        let choice = random();
        let item;
        for (let j = 0;j<normalized.length;j++) {
            item = normalized[j];
            if (choice <= item.fitness) {
                break;
            }
        }
        newGeneration.push(create(item.type));
    }
    return newGeneration;
}

export function stat(individuals) {
    let fitness = {
        h: {
            f: 0,
            cnt: 0
        },
        d: {
            f: 0,
            cnt: 0
        }
    };
    individuals.forEach(e => {
        fitness[e.type].f += e.fitness;
        fitness[e.type].cnt += 1
    });
    for (let type of Object.keys(fitness)) {
        let stat = fitness[type];
        console.log(`${type}: ${stat.cnt}, ${stat.f / stat.cnt}`);
    }
    console.log(fitness);
    return fitness;
}

export function run(startPopulation) {
    const population = selectProportional(startPopulation);
    population.forEach(e => {
        e.fitness = 0;
    });
    fight(population);
    return population;
}
