<template>
    <div id="points">
        <svg>
            <rect x="0" y="0" />
        </svg>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex';
    import { run, init_population } from '../simulator';
    import * as d3 from 'd3';
    const R = 15;
    const WIDTH = 600;
    const HEIGHT = 600;
    const pack = d3.pack()
                   .size([WIDTH, HEIGHT]);

    export default {
        data() {
            return {
            }
        },
        methods: {
            init() {
                let pointsWidth = d3.select("#points")
                                    .node()
                                    .getBoundingClientRect()
                                    .width;
                d3.select('#points svg')
                  .attr('width', WIDTH)
                  .attr('height', HEIGHT);

                d3.select('#points rect')
                  .attr('width', WIDTH)
                  .attr('height', HEIGHT)
                  .attr('fill', 'yellow')
                  .attr('fill-opacity', 0.5)
                  .attr('rx', R);
            },
            color(d) {
                switch(d.data.type) {
                    case 'd':
                        return d3.schemeCategory10[0];
                    case 'h':
                        return d3.schemeCategory10[3];
                    default:
                        throw new Error('Unknown type: ' + d.data.type);
                }
            },
            calcStroke(d) {
                let stat = this.$store.state.stat;
                let lastStat = stat[stat.length-1];
                let avg = lastStat[d.data.type].f / lastStat[d.data.type].cnt;
                if (d.data.fitness > 100) {
                    return R*0.5;
                } else if (d.data.fitness >= 0) {
                    return R*0.2;
                } else {
                    return 0;
                }
            }
        },
        mounted() {
            this.init();
        },
        computed: {
            pointNodes() {
                console.log('compute');
                return this.$store.state.pointNodes;
            }
        },
        watch: {
            pointNodes: function(newNodes) {
                const root = d3.hierarchy({children: newNodes})
                               .sum(d => 1);
                const svg = d3.select('#points svg');
                const circle = svg.selectAll('circle')
                                  .data(pack(root).leaves());
                circle.exit()
                      .remove();

                circle.enter()
                      .append('circle')
                        .attr('cx', d => d.x)
                        .attr('cy', d => d.y)
                        .attr('fill', this.color)
                        .attr('stroke-width', d => this.calcStroke(d))
                        .attr('stroke', 'black')
                        .attr('r', R)
                        .html(d => `<title>${d.data.fitness}</title>`);

                circle.merge(circle)
                      .attr('fill', this.color)
                      .attr('stroke-width', d => this.calcStroke(d))
                      .attr('cx', d => d.x)
                      .attr('cy', d => d.y)
                      .html(d => `<title>${d.data.fitness}</title>`);
            }
        }
    }
</script>
